import http from "../utils/loginAxios";

export function getQRCodefreeApi(data) {
  return http({
    method: "post",
    url: "/hky/authPerson/queryById",
    data: data,
  });
}

export function getPhonefrozen(data) {
  return http({
    method: "post",
    url: "/hky/authPerson/addByPhone",
    data: data,
  });
}

export function getfrozen(data) {
  return http({
    method: "post",
    url: "/hky/account/accountAuth/frozen",
    data: data,
  });
}

export function getunfrozen(data) {
  return http({
    method: "post",
    url: "/hky/account/accountAuth/unfrozen",
    data: data,
  });
}
