import axios from "axios";
import { Message } from "element-ui";
import router from "../router";

const request = axios.create({
  // baseURL: "http://10.90.0.5:33133", //测试
  // baseURL: "http://10.21.27.172:25400", //正式
  // timeout: 6000,
  headers: {
    "Content-type": "application/json;charset=utf-8",
  },
});

request.defaults.withCredentials = true;

// request拦截器
request.interceptors.request.use(
  (request) => {
    let token = localStorage.getItem("token");
    // request.headers['token'] = sessionStorage.getItem('token') // 让每个请求携带token--['X-token']为自定义key 请根据实际情况自行修改
    request.headers["Authorization"] = token;
    return request;
  },
  (error) => {
    Promise.reject(error);
  }
);

// // response拦截器
request.interceptors.response.use(
  (response) => {
    // console.log(response)
    // return Promise.resolve(response.data)
    if (response.data.code === "00000") {
      return Promise.resolve(response.data);
    } else if (response.data.code === "30009") {
      localStorage.clear();
      Message.error(response.data.message);
      router.push("/login");
    } else {
      Message.error({
        message: response.data.message || "啊呀 报错了",
        showClose: true,
      });

      return Promise.reject("error");
    }
  }
  // (error) => {
  //   // 下面是接口回调的satus ,暂时加上简单的错误页面，后期可以重新设计
  //   if (error.response.status === 403) {
  //     router.push({
  //       path: "/error/403",
  //     });
  //   } else if (error.response.status === 404) {
  //     router.push({
  //       path: "/error/404",
  //     });
  //   } else if (error.response.status === 502) {
  //     router.push({
  //       path: "/error/502",
  //     });
  //   } else if (error.response.status === 504) {
  //     Message.error({
  //       message: "网络请求超时，请稍后重试!",
  //       showClose: true,
  //     });
  //   } else {
  //     Message.error({
  //       message: "未知错误请稍后重试!",
  //       showClose: true,
  //     });
  //   }
  //   Message.error({
  //     message: "未知错误请稍后重试!",
  //     showClose: true,
  //   });
  //   // console.log('resolve')
  //   return Promise.reject(error);
  // }
);

export default request;
