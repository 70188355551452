import Vue from "vue";
import Vuex from "vuex";
import tab from "./tab";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 用户登录号码
    phone: localStorage.getItem("phone"),
    // 是否已实名
    renZheng: localStorage.getItem("ShiM"),
    searchParams: {}
  },
  mutations: {
    Setphone: (state, data) => {
      state.phone = data;
      localStorage.setItem("phone", data);
    },

    SetSearchParams: (state, data) => {
      state.searchParams = data;
    },
    SetShiM: (state, data) => {
      state.token = data;
      localStorage.setItem("ShiM", data);
    },
  },
  modules: {
    tab,
  },
});
