import axios from "axios";
import { Message } from "element-ui";

const request = axios.create({
  // baseURL: "http://10.90.0.5:33133", //测试
  // baseURL: "http://10.21.27.172:25400", //正式
  // timeout: 6000,
  headers: {
    "Content-type": "application/json;charset=utf-8",
  },
});

request.defaults.withCredentials = true;

export default request;
