<template>
  <div class="cont">
    <div class="contentstytle">
      <div class="left">
        <img v-if="false" src="../assets/ky_logo_top.png" style="width:50%;margin: 20px">
        <img v-if="false" src="../assets/logo.png" style="width: 50%; margin: 20px" alt="" />
        <img v-if="false" src="../assets/left-logo.png" style="width: 50%; margin: 20px" alt="" />
          <div style="text-align: left;
          height: 70px;
          font-weight: bold;
          font-size: 38px;
          color: #ffffff;
          display: flex;
          margin: 28px 0 0 28px;
          align-items: center">
              <img style="height: 100%;
              margin-right: 8px;
              width: auto;
              border-radius: 50%" src="../assets/bm-logo-main.png"
                    alt="" />
              巴马智慧养老便民服务平台
          </div>
        <img src="../assets/login_1.png" alt="" />
      </div>
      <div class="right">
        <!-- 账号登录实名认证 -->
        <div v-if="shim == 0" class="shimstyle">
          <div class="titlem">扫码认证</div>
          <div class="erwei">
            <img src="../assets/erweima.png" alt="" />
            <div class="QRcode-box" @click="handleGetQRCode()">
              <img src="../assets/Qrimg.png" alt="" />
              <!-- <QrCode class="qcode" :url="url"></QrCode> -->
              <div class="QRCode-tip" v-if="cantGetQRCode">
                <span
                  >噢哟 二维码过期了<br />
                  点击刷新
                </span>
              </div>
            </div>
          </div>
          <div class="texts">请使用手机扫描二维码,实名认证后进行操作</div>
        </div>
        <!-- 账号登录页 -->
        <div v-else-if="log == 1" class="login">
          <p class="text">
            <span>统一登录</span>
            <span>LOGIN</span>
          </p>
          <el-form
            ref="loginForm"
            :model="user"
            :rules="loginFormRules"
            class="inputs"
          >
            <el-form-item class="phoneinp" prop="phone">
              <el-input
                prefix-icon="el-icon-mobile"
                placeholder="手机号"
                v-model="user.phone"
                clearable
                @input="phoneblur"
                class="inp"
              >
              </el-input>
            </el-form-item>
            <el-form-item class="in2" prop="code">
              <div style="display: flex">
                <el-input
                  v-model="user.code"
                  placeholder="请输入验证码"
                  @input="phoneblur"
                  @keyup.enter.native="login"
                  clearable
                ></el-input>
                <el-button
                  round
                  :disabled="btndisable"
                  :style="
                    btndisable
                      ? 'margin-left: 8px; color: #fff; height: 38px;font-size: 14px; background-color: #b9b9b9;'
                      : 'margin-left: 8px; color: #ea5f9c; height: 38px;font-size: 14px; background-color: #fff; border: 1px solid #ea5f9c;'
                  "
                  @click="getCodde()"
                  >获取验证码</el-button
                >
              </div>
              <div class="msg" v-show="msg === 1">
                {{ content }}
              </div>
            </el-form-item>
            <el-button
              :disabled="loginbtn"
              :class="loginbtn ? 'cbtn' : 'cdbtn'"
              class="btn"
              @click="login"
              >登录</el-button
            >
            <div class="footer">
              <span>手机丢失或号码停用？</span>
              <span @click="dongjie">立即冻结账户</span>
            </div>
          </el-form>
        </div>
        <!-- 冻结账号 -->
        <div v-if="dongj == 1" class="freestyle">
          <div class="titled">冻结账号</div>
          <el-form :rules="loginFormRules">
            <el-form-item class="phoneinp" prop="freephone">
              <el-input
                prefix-icon="el-icon-mobile"
                placeholder="手机号"
                v-model="freephone"
                clearable
                class="inp"
              >
              </el-input>
            </el-form-item>
          </el-form>

          <el-button style="margin: 20px 0" class="btn" @click="freeze"
            >冻结号码</el-button
          >
          <div @click="gologin" class="back">返回登录</div>
        </div>
        <!-- 冻结实名认证 -->
        <div v-if="shimDJ == 0" class="shimstyle">
          <div class="titlem">扫码认证</div>
          <div class="erwei">
            <img src="../assets/erweima.png" alt="" />
            <div class="QRcode-box" @click="handleGetQRCodeD()">
              <img src="../assets/Qrimg.png" alt="" />
              <!-- <QrCode class="qcode" :url="url"></QrCode> -->
              <div class="QRCode-tip" v-if="cantGetQRCode1">
                <span
                  >噢哟 二维码过期了<br />
                  点击刷新
                </span>
              </div>
            </div>
          </div>
          <div class="texts">请使用手机扫描二维码,实名认证后进行操作</div>
        </div>
        <!-- 认证成功后显示 -->
        <div v-if="succe == true" class="freestyle">
          <div class="titled">扫码认证</div>
          <div style="margin-bottom: 10px">
            <img src="../assets/登录-图标-认证通过.png" alt="" />
          </div>
          <p style="font-size: 16px; color: #666666; margin: 25px 0">
            认证通过
          </p>
        </div>
        <!-- 冻结实人认证成功后显示的页面 -->
        <div v-if="Freezesucce == true" class="freestyle">
          <div class="titled">认证通过</div>
          <div style="margin-bottom: 10px">
            <img src="../assets/登录-图标-认证通过.png" alt="" />
          </div>
          <p style="font-size: 16px; color: #666666; margin: 25px 0">
            您的账号已冻结，将不能使用本账号进行统一登录。
          </p>
        </div>
        <!-- 解冻实人认证二维码 -->
        <div v-if="JDshi == true" class="shimstyle">
          <div class="titlem">扫码认证</div>
          <div class="erwei">
            <img src="../assets/erweima.png" alt="" />
            <div class="QRcode-box" @click="handleunfreeze()">
              <img src="../assets/Qrimg.png" alt="" />
              <!-- <QrCode class="qcode" :url="url"></QrCode> -->
              <div class="QRCode-tip" v-if="unfreezeQRCode">
                <span
                  >噢哟 二维码过期了<br />
                  点击刷新
                </span>
              </div>
            </div>
          </div>
          <div class="texts">请使用手机扫描二维码,实名认证后进行操作</div>
        </div>
        <!-- 解冻成功后显示的页面 -->
        <div v-if="unfreeze == true" class="freestyle">
          <div class="titled">认证通过</div>
          <div style="margin-bottom: 10px">
            <img src="../assets/登录-图标-认证通过.png" alt="" />
          </div>
          <p style="font-size: 16px; color: #666666; margin: 25px 0">
            您的账号已解冻，可正常使用。
          </p>
        </div>
        <!-- 账号异常冻结显示的页面 -->
        <div v-if="djie == true" class="freestyle">
          <div class="titled">账号已被冻结</div>
          <div style="margin-bottom: 10px">
            <img src="../assets/dongjie.png" alt="" />
          </div>
          <p style="font-size: 16px; color: #666666; margin: 10px 0">
            请使用手机扫描二维码,实名认证后进行操作
          </p>
          <div class="jiedbtn">
            <el-button @click="gologin" round>暂不处理</el-button>
            <el-button @click="JieDong" round>解冻账号</el-button>
          </div>
        </div>
        <!-- 5次后需要图形验证 -->
        <div v-if="yanz == 2" class="freestyle">
          <div class="yantitle">请先按图形输入正确的字符</div>
          <Vcanvas ref="child" :changeCode.sync="identifyCode"></Vcanvas>
          <div class="shua" @click="refresh">
            <span>看不清？点击刷新</span>
            <img :class="[state ? 'g' : 'a']" src="../assets/shua.png" alt="" />
          </div>
          <div class="yanma">
            <el-input
              placeholder="请输入图中字符"
              v-model="yanCode"
              clearable
              @input="disCode"
              class="inp"
            ></el-input>
            <el-button
              :class="codebtn ? 'cbtn' : 'cdbtn'"
              :disabled="codebtn"
              round
              >获取验证码</el-button
            >
          </div>
        </div>
      </div>
      <div class="footer-container">
        <img v-if="false" style="margin-right: 8px" src="http://www.beian.gov.cn/img/ghs.png" alt="" />
        <!---->
        <a class="footer-text" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">
          <div style="text-align: center;">技术支持：中国—东盟信息港股份有限公司</div>
          Copyright © 广西东信健康科技发展有限公司 All Rights Reserved &ensp;&ensp; 桂ICP备2022008933号-1
        </a>
        <a v-if="false" class="footer-text">
          <div>技术支持：中国—东盟信息港股份有限公司</div>
          Copyright © 广西东信健康科技发展有限公司 All Rights Reserved &ensp;&ensp; 桂ICP备2022008933号-1
        </a>
      </div>

    </div>
  </div>
</template>

<script>
// import { time } from "echarts";
import { getCode, glogin } from "../api/user";
import { userInfo } from "../api/data";
import Vcanvas from "../components/Vcanvas.vue";
import { getQRCodeStatusApi, getQRCode } from "../api/QRloginApi";
import {
  getfrozen,
  getPhonefrozen,
  getunfrozen,
  getQRCodefreeApi,
} from "../api/QrFreeze";
// 生成二维码
import QrCode from "../components/qrCode.vue";

var timer; //定时器
var timer2; //定时器
var timer3; //定时器
export default {
  components: { Vcanvas, QrCode },

  data() {
    return {
      // 二维码地址
      imgUrl: "",
      shimDJ: 6,
      // 解冻页
      JDshi: false,
      unfreeze: false,
      // 登录认证轮询计数
      intervalResetCount: 0,
      intervalSencondCount: 0,
      QRCodeToken: localStorage.getItem("token"),
      cantGetQRCode: false,
      // 冻结认证轮询计数
      intervalResetCount1: 0,
      intervalSencondCount1: 0,
      cantGetQRCode1: false,
      // 冻结id
      freezeId: "",
      // 解冻认证轮询计数
      unfreezeResetCount: 0,
      unfreezeSencondCount: 0,
      unfreezeQRCode: false,
      // url:
      //   "http://10.90.0.6:34809/ee-app-fe/certification_identity?token=" +
      //   localStorage.getItem("token"),
      urls: "",
      // 登录成功认证后显示
      succe: false,
      Freezesucce: false,
      // 当前生成的验证码
      identifyCode: "",
      // 禁用验证码按钮
      codebtn: true,
      //输入的验证码
      yanCode: "",
      // 刷新图标
      state: false,
      user: {
        phone: "",
        code: "",
      },
      // 手机号登录表单的验证规则对象
      loginFormRules: {
        // 验证用户名是否合法
        phone: [
          { required: true, message: "请输入登录手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号格式",
            trigger: "blur",
          },
        ],
        // 验证验证码
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { pattern: /^\d{6}$/, message: "请输入6位验证码", trigger: "blur" },
        ],
        freephone: [
          { required: true, message: "请输入冻结手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号格式",
            trigger: "blur",
          },
        ],
      },
      // 冻结号码
      freephone: "",
      // 是否已实名认证
      shim: this.$store.state.renZheng,
      log: 1,
      btndisable: false,
      // 冻结
      dongj: 0,
      djie: false,
      // 图形验证
      yanz: 0,
      // 登录按钮置灰
      loginbtn: true,
      msg: 0,
      QrId: "",
      QrIdD: "",
      QrIdJ: "",
      content: "短信已发送",
      totalTime: 60, //倒计时
      canClick: true,
      duanx: 0,
    };
  },
  // watch: {
  //   user(newValue, oldValue) {
  //     console.log(newValue, "新值", oldValue, "旧值");
  //   },
  //   deep: true,
  // },
  mounted() {
    if (this.$store.state.renZheng == 0) {
      this.getQRCodes();
      this.startSocket();
    }
  },
  methods: {
    //  登录调起实人认证接口
    getQRCodes() {
      let data = {
        authType: "0", //0: 实人认证 1: 密码修改认证
      };
      getQRCode(data).then((res) => {
        console.log(res, "认证=======");
        if (res.code == "00000") {
          this.QrId = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    //   登录认证自动获取二维码和二维码轮询判断
    startSocket() {
      // this.QRCodeToken =
      //   new Date().getTime() + (Math.random() + "").replace(".", "");
      // this.url =
      //   "http://10.90.0.6:34809/ee-app-fe/certification_identity?token=" +
      //   localStorage.getItem("token");
      this.cantGetQRCode = false;
      var _this = this;
      clearInterval(timer);
      // _this.getQRCodes();
      timer = setInterval(() => {
        this.intervalSencondCount++;
        if (this.intervalSencondCount > 60) {
          this.intervalSencondCount = 0;
          this.intervalResetCount++;
        }
        // if (this.intervalResetCount > 2) {
        //   this.cantGetQRCode = true;
        //   clearInterval(timer);
        // }
        let id = {
          id: this.QrId,
        };
        getQRCodeStatusApi(id)
          .then((res) => {
            // console.log(res, "88888");
            // debugger;
            // return;
            if (res.code != "00000") {
              this.cantGetQRCode = true;
              clearInterval(timer);
              this.$message.error(res.data.message);
              // Message.error({
              //   message: '请求失败了',
              //   showClose: true,
              // })
              return false;
            }
            // 1 已扫码
            // 2 登录成功
            // 3 认证失败
            // 4 取消认证
            this.$store.commit("SetShiM", res.data.authStatus);
            if (res.data.authStatus === 1) {
              // 认证中+1
              _this.intervalResetCount++;
              // 二维码过期 重新获取二维码
              //   如果过期次数超过3次 则不再获取二维码 并提示二维码过期
              if (_this.intervalResetCount > 25) {
                this.cantGetQRCode = true;
                clearInterval(timer);
                return false;
              }
              _this.startSocket();
            } else if (res.data.authStatus === 2) {
              // 登陆成功 停止轮询 并获取用户信息
              clearInterval(timer);
              this.succe = true;
                setTimeout(() => {
                  this.$router.push({ path: "/main" });
                }, 2000);

              this.shim = 8;
              this.log = 8;

              // _this.getUserInfo();
              return;
            } else if (res.data.authStatus === 3) {
              // 认证失败
              this.$message.error("认证失败");
              clearInterval(timer);
            } else {
              // 取消
              this.$message.warning("认证已取消");
              clearInterval(timer);
            }
          })
          .catch(() => {
            this.cantGetQRCode = true;
            clearInterval(timer);
            // Message.error({
            //   message: '请求没成功',
            //   showClose: true,
            // })
            return false;
          });
      }, 4000);
    },

    //  登录认证 手动获取二维码 并重置计数；
    handleGetQRCode() {
      this.intervalResetCount = 0;
      this.intervalSencondCount = 0;
      this.cantGetQRCode = false;
      this.startSocket(); //获取二维码
    },

    // 解冻调二维码
    JieDong() {
      this.JDshi = true;
      this.djie = false;
      this.unfreeQRCodes();
      this.unfreezeSocket();
    },
    // 解冻认证
    handleunfreeze() {
      this.unfreezeResetCount = 0;
      this.unfreezeSencondCount = 0;
      this.unfreezeQRCode = false;
      this.unfreezeSocket();
    },
    unfreeQRCodes() {
      let data = {
        authType: "3", //2:冻结账号 3解冻
        phone: this.user.phone,
      };
      getPhonefrozen(data).then((res) => {
        console.log(res, "认证j解冻=======");
        if (res.data.code == "00000") {
          this.freezeId = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    unfreezeSocket() {
      this.unfreezeQRCode = false;
      var _this = this;
      clearInterval(timer3);
      // _this.getQRCodes1();
      timer3 = setInterval(() => {
        this.unfreezeSencondCount++;
        if (this.unfreezeSencondCount > 60) {
          this.unfreezeSencondCount = 0;
          this.unfreezeResetCount++;
        }
        let id = {
          id: this.freezeId,
        };
        getQRCodefreeApi(id)
          .then((res) => {
            console.log(res, "88888解冻");
            // debugger;
            // return;
            if (res.data.code != "00000") {
              this.unfreezeQRCode = true;
              clearInterval(timer3);
              this.$message.error(res.data.message);
              // Message.error({
              //   message: '请求失败了',
              //   showClose: true,
              // })
              return false;
            }
            // 1 已扫码
            // 2 登录成功
            // 3 认证失败
            // 4 取消认证
            if (res.data.data.authStatus === 1) {
              // 认证中+1
              _this.unfreezeQRCode++;
              // 二维码过期 重新获取二维码
              //   如果过期次数超过次 则不再获取二维码 并提示二维码过期
              if (_this.unfreezeQRCode > 25) {
                this.unfreezeQRCode = true;
                clearInterval(timer3);
                return false;
              }
              _this.unfreezeSocket();
            } else if (res.data.data.authStatus === 2) {
              // 冻结成功 停止轮询 并获取用户信息
              clearInterval(timer3);

              let params = {
                authPersonId: this.freezeId,
                phone: this.user.phone,
              };
              getunfrozen(params).then((res) => {
                console.log(res, "解结");
                // 冻结成功后显示的页面
                if (res.data.code == "00000") {
                  this.unfreeze = true;
                  this.JDshi = false;
                  if (this.unfreeze) {
                    setTimeout(() => {
                      this.unfreeze = false;
                      location.reload();
                      // this.$router.push({ path: "/login" });
                      this.log = 1;
                    }, 5000);
                  }
                } else {
                  this.$message.error(res.data.message);
                }
                this.shimDJ = 8;
                this.shim = 8;
              });

              // _this.getUserInfo();
              return;
            } else if (res.data.data.authStatus === 3) {
              // 认证失败
              clearInterval(timer3);
              this.$message.error("认证失败");
            } else {
              // 取消
              clearInterval(timer3);
              this.$message.warning("认证已取消");
            }
          })
          .catch(() => {
            this.unfreezeQRCode = true;
            clearInterval(timer3);
            // Message.error({
            //   message: '请求没成功',
            //   showClose: true,
            // })
            return false;
          });
      }, 5000);
    },

    // 冻结认证
    getQRCodes1() {
      let data = {
        authType: "2", //2:冻结账号 3解冻
        phone: this.freephone,
      };
      getPhonefrozen(data).then((res) => {
        console.log(res, "认证冻结=======");
        if (res.data.code == "00000") {
          this.freezeId = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 冻结认证
    startSocket1() {
      this.cantGetQRCode1 = false;
      var _this = this;
      clearInterval(timer2);
      // _this.getQRCodes1();
      timer2 = setInterval(() => {
        this.intervalSencondCount1++;
        if (this.intervalSencondCount1 > 60) {
          this.intervalSencondCount1 = 0;
          this.intervalResetCount1++;
        }
        let id = {
          id: this.freezeId,
        };
        getQRCodefreeApi(id)
          .then((res) => {
            // console.log(res, "88888冻");
            // debugger;
            // return;
            if (res.data.code != "00000") {
              this.cantGetQRCode1 = true;
              clearInterval(timer2);
              this.$message.error(res.data.message);
              // Message.error({
              //   message: '请求失败了',
              //   showClose: true,
              // })
              return false;
            }
            // 1 已扫码
            // 2 登录成功
            // 3 认证失败
            // 4 取消认证
            if (res.data.data.authStatus === 1) {
              // 认证中+1
              _this.intervalResetCount1++;
              // 二维码过期 重新获取二维码
              //   如果过期次数超过次 则不再获取二维码 并提示二维码过期
              if (_this.intervalResetCount1 > 25) {
                this.cantGetQRCode1 = true;
                clearInterval(timer2);
                return false;
              }
              _this.startSocket1();
            } else if (res.data.data.authStatus === 2) {
              // 冻结成功 停止轮询 并获取用户信息
              clearInterval(timer2);

              let params = {
                authPersonId: this.freezeId,
                phone: this.freephone,
              };
              getfrozen(params).then((res) => {
                console.log(res, "冻结");
                // 冻结成功后显示的页面
                if (res.data.code === "00000") {
                  this.Freezesucce = true;
                  if (this.Freezesucce) {
                    setTimeout(() => {
                      this.Freezesucce = false;
                      this.$router.push({ path: "/login" });
                      this.log = 1;
                    }, 5000);
                  }
                } else {
                  this.$message.error(res.data.message);
                }
                this.shimDJ = 8;
                this.shim = 8;
              });

              // _this.getUserInfo();
              return;
            } else if (res.data.data.authStatus === 3) {
              // 认证失败
              clearInterval(timer2);
              this.$message.error("认证失败");
            } else {
              // 取消
              clearInterval(timer2);
              this.$message.warning("认证已取消");
            }
          })
          .catch(() => {
            this.cantGetQRCode1 = true;
            clearInterval(timer2);
            // Message.error({
            //   message: '请求没成功',
            //   showClose: true,
            // })
            return false;
          });
      }, 4000);
    },
    //冻结
    handleGetQRCodeD() {
      this.intervalResetCount1 = 0;
      this.intervalSencondCount1 = 0;
      this.cantGetQRCode1 = false;
      this.startSocket1();
    },

    // 验证码未输入禁用按钮
    disCode() {
      // console.log(this.yanCode);
      if (
        this.yanCode !== "" &&
        this.yanCode.trim() !== "" &&
        this.yanCode !== undefined
      ) {
        this.codebtn = false;
      } else {
        this.codebtn = true;
      }
    },
    // 刷新验证码图形
    refresh() {
      this.state = !this.state;
      this.$nextTick(() => {
        this.$refs.child.changeCode();
      });
    },
    phoneblur() {
      if (
        this.user.phone == undefined ||
        this.user.code == undefined ||
        this.user.phone.trim() == "" ||
        this.user.code.trim() == ""
      ) {
        this.loginbtn = true;
      } else {
        this.loginbtn = false;
      }
    },
    // 登录
    login() {
      let datas = {
        account: this.user.phone,
        smsCode: this.user.code,
      };
      glogin(datas).then((res) => {
        console.log(res.data.data, "token");

        if (res.data.code == "00000") {
          localStorage.setItem("token", res.data.data);
          userInfo().then((res) => {
            console.log(res, "用户信息");
            if (res.code == "00000") {
              this.$store.commit("Setphone", res.data.accountName);
              // 后台返回是否已实名authStatus == "0"未实名
              this.$store.commit("SetShiM", res.data.authStatus);
              if (res.data.authStatus == "0") {
                // this.handleGetQRCode();
                this.$store.commit("SetShiM", res.data.authStatus);
                this.getQRCodes();
                this.startSocket();
                this.shim = res.data.authStatus;
                this.log = 2;
              } else {
                this.$router.push("/main");
              }
            } else {
              this.$message.error(res.data.message);
            }
          });
          this.user.phone = "";
          this.user.code = "";
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    // 获取验证码
    getCodde() {
      if (this.user.phone.trim() == "" || this.user.phone == undefined) {
        this.$message.warning("请输入号码再获取");
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.user.phone)) {
        this.$message.error("请输入正确的号码");
        return;
      }
      let data = {
        account: this.user.phone,
      };
      getCode(data).then((res) => {
        console.log(res, "验证码");
        if (res.data.code == "00000") {
          return;
        } else if (res.data.code === "30008") {
          // this.$message.error(res.data.message);
          this.djie = true;
          this.log = 8;
        } else {
          this.$message.error(res.data.message);
        }
      });
      // 后台返回点击获取验证超过5次后触发图形验证
      // this.yanz = 2;
      // this.log = 0;
      this.btndisable = true;
      this.msg = 1;
      if (!this.canClick) {
        return;
      } //节流
      this.canClick = false;
      this.content = this.totalTime + "s后重新发送";
      let clock = window.setInterval(() => {
        this.totalTime--;
        this.content = this.totalTime + "s后重新发送";
        if (this.totalTime < 0) {
          //当倒计时小于0时清除定时器
          window.clearInterval(clock); //关闭
          this.btndisable = false;
          this.content = "";
          this.totalTime = 60;
          this.canClick = true; //这里重新开启
        }
      }, 1000);
    },
    dongjie() {
      this.dongj = 1;
      this.shim = 3;
      this.log = 0;
    },
    // 冻结
    freeze() {
      // console.log("冻结", this.freephone);
      if (this.freephone.trim() == "" || this.freephone == undefined) {
        this.$message.warning("请输入需要冻结的号码！");
      } else {
        let reg = /^1[3-9]\d{9}$/;
        if (reg.test(this.freephone)) {
          this.getQRCodes1();
          this.startSocket1();
          // this.urls =
          //   "http://10.208.12.127:3000/certification_identity?accountName=" +
          //   this.freephone;
          this.dongj = 6;
          this.shim = 3;
          this.shimDJ = 0;
          this.log = 0;
        } else {
          this.$message.warning("请输入正确的号码！");
        }
      }
    },
    gologin() {
      location.reload();
      this.log = 1;
      this.dongj = 0;
      this.djie = false;
      this.shim = 3;
    },
  },
};
</script>

<style lang="scss" scoped>
.erwei {
  position: relative;
  .QRcode-box {
    cursor: pointer;
    //   background: cyan;
    // width: 11rem;
    // height: 11rem;
    // position: unset;
    // margin: 0 auto 20px;
    // position: relative;
    img,
    .QRCode-tip {
      width: 11.5rem;
      height: 11.5rem;
      margin: 0 auto 20px;
      display: inline-block;
      position: absolute;
      top: 0.3rem;
      left: 7.2rem;
      // width: 100%;
      // height: 100%;
    }
    .QRCode-tip {
      background: rgba($color: #000000, $alpha: 0.8);
      z-index: 100;
      span {
        z-index: 100;
        width: 100%;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 0.8rem;
        color: white;
        line-height: 3rem;
        transition: all 0.5s;

        &:hover {
          color: rgb(214, 212, 212);
        }
      }
    }
  }
  .qcode {
    position: absolute;
    top: 8px;
    left: 120px;
  }
}

.phoneinp {
  ::v-deep .el-form-item__error {
    left: 60px !important;
  }
}
::v-deep .el-form-item__error {
  margin-bottom: 15px;
}
.cbtn {
  background: #aaaaaa !important;
  color: #fff !important;
}
.cdbtn {
  background-image: linear-gradient(270deg, #f166a3 0%, #c13a75 100%);
  border: none;
  color: #fff;
}
.yanma {
  .inp {
    width: 320px !important;
    height: 36px !important;
    color: #b9b9b9;
    margin-top: 15px;
  }

  ::v-deep .el-button.is-round {
    width: 320px;
    height: 36px;
    background-image: linear-gradient(270deg, #f166a3 0%, #c13a75 100%);
    border: none;
    color: #fff;
    margin: 25px 0;
  }
  ::v-deep .el-button.is-round:active {
    width: 320px;
    height: 36px;
    opacity: 0.7;
    background-image: linear-gradient(270deg, #f166a3 0%, #c13a75 100%);
    border: none;
    color: #fff;
  }
}
.shua {
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  color: #999999;
  display: flex;
  box-sizing: border-box;
  width: 140px;
  margin: 5px auto;
  text-align: center;
  .a {
    display: inline-block;
    height: 15px;
    width: 15px;
    margin-top: 1px;
    margin-left: 5px;
    transform: rotate(360deg);
    transition: all 2s; // 可配置
    align-content: center;
  }
  .g {
    height: 15px;
    width: 15px;
    transform: rotate(-360deg);
    transition: all 2s; // 可配置
  }
  > span {
    display: inline-block;
    align-items: center;
  }
}
.jiedbtn {
  margin: 20px 0;
  ::v-deep .el-button:nth-child(1) {
    background: #fff !important;
    border: 1px solid #e65c99 !important;
    color: #e65c99;
    width: 140px;
    height: 36px;
  }
  ::v-deep .el-button:nth-child(1):active {
    background: #f166a3 !important;
    color: #fff;
  }
  ::v-deep .el-button:nth-child(2) {
    background-image: linear-gradient(270deg, #f166a3 0%, #c13a75 100%);
    border: none !important;
    width: 140px;
    height: 36px;
    color: #fff;
  }
  ::v-deep .el-button:nth-child(2):active {
    background-image: linear-gradient(270deg, #f166a3 100%, #c13a75 0%);
  }
}
.footer {
  margin: 15px 0;
  > span {
    color: #666;
    font-size: 14px;
  }
  > span:nth-child(2) {
    color: #ff7b51;
    cursor: pointer;
  }
}
.btn {
  width: 300px;
  margin-top: 10px;
  background-image: linear-gradient(270deg, #f166a3 0%, #c13a75 100%);
  border-radius: 18px;
  color: #fff;
  border: none;
}
.btn:hover {
  color: #fff;
  background-image: linear-gradient(270deg, #f166a3 100%, #c13a75 0%);
}
.msg {
  font-size: 14px;
  margin-top: 5px;
  color: #666;
}
.duanx {
  font-size: 14px;
  margin-top: 5px;
  color: red;
}
.inp {
  border-radius: 20px !important;
  width: 300px;
}
.in2 {
  display: flex;
  width: 300px;
  margin: 20px auto;
  ::v-deep .el-input__inner {
    width: 170px;
  }
}
::v-deep .el-input__inner {
  border-radius: 20px !important;
}
.contentstytle {
  display: flex;
  // justify-content: space-between;
  height: 100vh;
  .left {
    width: 50%;
    height: 100%;
    background-image: url("../assets/login_2.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    img {
      width: 100%;
      display: inline-block;
      // height: 100%;
      opacity: 1;
    }
  }
  .right {
    width: 60%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../assets/login_r.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .login,
    .shimstyle,
    .freestyle {
      width: 416px;
      box-sizing: border-box;
      // height: 100%;
      background: #fff;
      border-radius: 4px;
      text-align: center;
      .text {
        margin: 30px 0;
        > span {
          font-size: 20px;
          font-weight: bold;
          color: #666;
          display: inline-block;
          margin-right: 10px;
        }
        > span:nth-child(2) {
          color: #bbb;
        }
      }
      .inputs {
        margin-bottom: 30px;
      }
    }
    .freestyle {
      .titled {
        font-size: 20px;
        font-weight: bold;
        color: #333;
        margin: 50px 0;
      }
      .yantitle {
        font-size: 20px;
        font-weight: bold;
        color: #333;
        margin: 30px 0;
      }
      .back {
        color: #ff7b51;
        font-size: 14px;
        margin-bottom: 20px;
        cursor: pointer;
      }
    }

    .shimstyle {
      .titlem {
        font-size: 20px;
        color: #333;
        font-weight: bold;
        margin: 20px 0;
      }
      .texts {
        font-size: 16px;
        margin: 25px 0;
        color: #666;
      }
    }
  }
}
.cont {
  height: 100vh;
  // width: 100%;
}

.footer-container {
  width: 100%;
  height: 60px;
  background-color: #3b3e4b;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;
}

  .footer-text {
    cursor: pointer;
    font-size: 14px;
    color: #ffffff;
    text-decoration: none;


  }
</style>
