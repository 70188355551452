import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import './element-variables.scss'
// import md5 from "js-md5";
// import JsEncrypt from "jsencrypt";
// import { Base64 } from "js-base64";

// Vue.use(Base64);
// Vue.prototype.Base64 = Base64;

// Vue.prototype.$md5 = md5;
// // JSEncrypt加密方法
// Vue.prototype.$getCode = function (password) {
//   // new一个对象
//   let jsEncrypt = new JsEncrypt();
//   jsEncrypt.setPublicKey(rsaPublicKey); // 设置 加密公钥
//   let signature = jsEncrypt.encrypt(password); // 进行加密
//   return signature;
// };

Vue.config.productionTip = false;
Vue.use(ElementUI);

router.beforeEach((to, from, next) => {
  // 路由发生变化时，页面title跟着变
  // console.log(router.meta.title);
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

// if (process.env.NODE_ENV === "development") require("@/api/mock");
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
