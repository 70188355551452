import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
// import Home from "../views/Home/Home.vue";

Vue.use(VueRouter);

const routes = [
  // 登录页面
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "巴马智慧养老便民服务平台-登录",
    },
  },
  // 重定向
  {
    path: "/",
    redirect: {
      name: "Login",
    },
    component: Login,
    meta: {
      title: "巴马智慧养老便民服务平台-登录",
    },
  },
  {
    path: "/main",
    name: "Main",
    component: () => import("../views/Main"),

    children: [
      {
        path: "/",
        name: "home",
        component: () => import("../views/Home/Home.vue"),
        meta: {
          title: "巴马智慧养老便民服务平台-我的应用",
        },
      },
    ],
  },
  {
    path: "/error",
    name: "Error",
    children: [
      {
        path: "/",
        name: "403",
        component: () => import("../views/Error/403.vue"),
        meta: {
          title: "巴马智慧养老便民服务平台",
        },
      },
      {
        path: "/404",
        name: "404",
        component: () => import("../views/Error/404.vue"),
        meta: {
          title: "巴马智慧养老便民服务平台",
        },
      },
      {
        path: "/502",
        name: "502",
        component: () => import("../views/Error/502.vue"),
        meta: {
          title: "巴马智慧养老便民服务平台",
        },
      },
    ],
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  // mode: "history",
  // mode: "hash",
  // base: process.env.BASE_URL,
  routes,
});

// 路由拦截器
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  let renZ = localStorage.getItem("ShiM");
  // console.log(renZ, "99999");
  if (to.path === "/login") {
    // if (token) {
    if (token && renZ == 1) {
      next("/main");
    } else {
      next();
    }
  } else {
    // if (!token || token == null) {
    if (!token || renZ == 0 || token == null) {
      next("/login");
    } else {
      next();
    }
  }
});

export default router;
