import request from "../utils/request";

export function userInfo() {
  return request({
    method: "post",
    url: "/hky/login/user/info",
    // headers: { Authorization: token },
  });
}

export function getApp(params) {
  return request({
    method: "post",
    url: "/hky/app/page",
    data: params,
  });
}

export function edictpass(params) {
  return request({
    method: "post",
    url: "/hky/account/update/password",
    data: params,
  });
}

export function getEstimate(params) {
  return request({
    method: "post",
    url: "/hky/app/estimate",
    data: params,
  });
}