import request from "../utils/loginAxios";

export function getCode(params) {
  return request({
    method: "post",
    url: "/hky/login/sms/code",
    data: params,
  });
}

export function glogin(params) {
  return request({
    method: "post",
    url: "/hky/login/sms/login",
    data: params,
  });
}
