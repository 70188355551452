import http from "../utils/request";

export function getQRCodeStatusApi(data) {
  return http({
    method: "post",
    url: "/hky/authPerson/queryById",
    data: data,
  });
}
export function getQRCode(data) {
  return http({
    method: "post",
    url: "/hky/authPerson/add",
    data: data,
  });
}
